var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c("template-select-toolbar", {
        attrs: { duct: _vm.duct, prjName: _vm.prjName },
        on: { "template-select": _vm.updateTemplateName }
      }),
      _c(
        "v-row",
        { staticStyle: { height: "calc(100% - 53px)" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-fade-transition",
                { attrs: { "hide-on-leave": "" } },
                [
                  _c(_vm.tmplComponent, {
                    tag: "component",
                    on: {
                      submit: _vm.showSubmitResults,
                      "response-update": _vm.updateResponse
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-0 pb-0", attrs: { cols: "2" } },
            [_c("response-card", { attrs: { response: _vm.response } })],
            1
          )
        ],
        1
      ),
      _c("tutti-dialog", {
        ref: "dialogSubmitResponse",
        attrs: {
          maxWidth: "700",
          actions: [
            {
              label: "Close",
              color: "indigo darken-1",
              text: true
            }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "green" } },
                  [_vm._v("mdi-check-circle")]
                ),
                _vm._v("Answers are submitted successfully ")
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _c("vue-json-pretty", { attrs: { data: _vm.sentResponse } })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }